import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import * as React from "react";
import * as yup from "yup";

// component
import Link from "../utils/link";
import Section from "../utils/section";
import { useFirebase } from "../utils/useFirebase";

const validationSchema = yup.object({
  name: yup.string().required(),
  piva: yup.string().required(),
  phone: yup.string().required(),
  email: yup.string().email().required(),
  address: yup.string().required(),
  note: yup.string(),
  privacy: yup.number().oneOf([1]),
});

interface CampioneGratuitoFormProps {
  language?: "it" | "en" | "es";
}
const CampioneGratuitoForm = ({ language }: CampioneGratuitoFormProps) => {
  const [isSent, setIsSent] = React.useState(false);
  const { firestore } = useFirebase();

  const translations = {
    it: {
      title: "Sei incuriosito dai nostri prodotti?",
      description:
        "Compila i campi qui sotto e riceverai all'indirizzo desiderato <strong>senza alcun costo</strong> una campionatura completa.",
      kit: {
        header: "Il tuo kit contiene:",
        items: [
          "8 salviette, 1 per ogni fragranza",
          "4 bavagli, 1 per ogni formato",
        ],
      },
      labels: {
        name: "Nome",
        phone: "Telefono",
        email: "Email",
        address: "Indirizzo",
        piva: "Partita iva",
        note: "Note",
        privacy: "Ho letto e accetto la",
        marketing:
          "Do il consenso al trattamento dei miei dati per fini commerciali",
        submit: "richiedi campione gratuito",
        successTitle: "Grazie per aver richiesto il campione gratuito.",
        successMessage:
          "Sarà contattato a breve da un nostro operatore per ricevere il Suo campione gratuito.",
      },
      privacyLink: "https://www.iubenda.com/privacy-policy/97449482",
      email: {
        subject: "Campione gratuito per {name} - Mettifogo S.r.l",
        text: `
            Gentile {name},
            saremo felici di inviarLe il nostro campione all'indirizzo: {address}.
            Partita IVA: {piva}.
            Contatto: {phone}, {email}.
            Note: {note}.
            Team Mettifogo S.r.l
          `,
        html: `
            <p>Gentile {name},</p>
            <p>Saremo felici di inviarLe il nostro campione all'indirizzo:</p>
            <p><strong>{address}</strong></p>
            <p>Partita IVA: <strong>{piva}</strong>.</p>
            <p>Contatto: <strong>{phone}</strong>, <strong>{email}</strong>.</p>
            <p>Note: {note}</p>
            <p><i>Team Mettifogo S.r.l</i></p>
          `,
      },
    },
    en: {
      title: "Are you intrigued by our products?",
      description:
        "Fill out the fields below and you will receive a complete sample kit at your desired address for free.",
      kit: {
        header: "Your kit contains:",
        items: [
          "1 wipe for each fragrance",
          "1 bib for each format and graphic",
        ],
      },
      labels: {
        name: "Name",
        phone: "Phone",
        email: "Email",
        address: "Address",
        piva: "VAT number",
        note: "Notes",
        privacy: "I have read and accept the",
        marketing:
          "I consent to the processing of my data for commercial purposes",
        submit: "Request free samples",
        successTitle: "Thank you for requesting the free sample.",
        successMessage:
          "You will be contacted shortly by one of our operators to receive your free sample.",
      },
      privacyLink: "https://www.iubenda.com/privacy-policy/97449482",
      email: {
        subject: "Free Sample for {name} - Mettifogo S.r.l",
        text: `
            Dear {name},
            We will be happy to send your free sample to the following address: {address}.
            VAT number: {piva}.
            Contact: {phone}, {email}.
            Notes: {note}.
            Team Mettifogo S.r.l
          `,
        html: `
            <p>Dear {name},</p>
            <p>We will be happy to send your free sample to the following address:</p>
            <p><strong>{address}</strong></p>
            <p>VAT number: <strong>{piva}</strong>.</p>
            <p>Contact: <strong>{phone}</strong>, <strong>{email}</strong>.</p>
            <p>Notes: {note}</p>
            <p><i>Team Mettifogo S.r.l</i></p>
          `,
      },
    },
    es: {
      title: "¿Está intrigado por nuestros productos?",
      description:
        "Rellena los campos a continuación y recibirás una muestra completa en la dirección deseada sin coste alguno.",
      kit: {
        header: "Tu kit contiene:",
        items: [
          "1 toallita para cada fragancia",
          "1 babero para cada formato y gráfico",
        ],
      },
      labels: {
        name: "Nombre",
        phone: "Teléfono",
        email: "Correo electrónico",
        address: "Dirección",
        piva: "Número de IVA",
        note: "Notas",
        privacy: "He leído y acepto la",
        marketing:
          "Doy mi consentimiento para el tratamiento de mis datos con fines comerciales",
        submit: "Solicitar muestra gratuita",
        successTitle: "Gracias por solicitar la muestra gratuita.",
        successMessage:
          "Será contactado pronto por uno de nuestros operadores para recibir su muestra gratuita.",
      },
      privacyLink: "https://www.iubenda.com/privacy-policy/97449482",
      email: {
        subject: "Muestra gratuita para {name} - Mettifogo S.r.l",
        text: `
            Estimado {name},
            Estaremos encantados de enviar su muestra gratuita a la siguiente dirección: {address}.
            Número de IVA: {piva}.
            Contacto: {phone}, {email}.
            Notas: {note}.
            Equipo Mettifogo S.r.l
          `,
        html: `
            <p>Estimado {name},</p>
            <p>Estaremos encantados de enviar su muestra gratuita a la siguiente dirección:</p>
            <p><strong>{address}</strong></p>
            <p>Número de IVA: <strong>{piva}</strong>.</p>
            <p>Contacto: <strong>{phone}</strong>, <strong>{email}</strong>.</p>
            <p>Notas: {note}</p>
            <p><i>Equipo Mettifogo S.r.l</i></p>
          `,
      },
    },
  };

  const t = translations[language || "it"];

  const formik = useFormik({
    initialValues: {
      name: "",
      piva: "",
      phone: "",
      email: "",
      address: "",
      note: "",
      privacy: 0,
      marketing: 0,
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      if (firestore) {
        const db = firestore.getFirestore();
        firestore
          .addDoc(firestore.collection(db, "contacts"), {
            to: [values.email],
            bcc: ["info@mettifogo.eu"],
            message: {
              subject: t.email.subject.replace("{name}", values.name),
              text: t.email.text
                .replace("{name}", values.name)
                .replace("{address}", values.address)
                .replace("{piva}", values.piva)
                .replace("{phone}", values.phone)
                .replace("{email}", values.email)
                .replace("{note}", values.note || "-"),
              html: t.email.html
                .replace("{name}", values.name)
                .replace("{address}", values.address)
                .replace("{piva}", values.piva)
                .replace("{phone}", values.phone)
                .replace("{email}", values.email)
                .replace("{note}", values.note || "-"),
            },
          })
          .then(() => {
            setIsSent(true);
            resetForm();
          });
      }
    },
  });

  return (
    <Section>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Stack spacing={1}>
            <Typography variant="h3" component="h2">
              {t.title}
            </Typography>
            <Typography dangerouslySetInnerHTML={{ __html: t.description }} />
          </Stack>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Stack spacing={1}>
            <Typography>{t.kit.header}</Typography>
            <ul>
              {t.kit.items.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </Stack>
        </Grid>
        {/* Form Section */}
        <Grid item xs={12} sm={6} md={6}>
          <form onSubmit={formik.handleSubmit}>
            <Stack spacing={2}>
              {/* Fields */}
              {["name", "phone", "email", "address", "piva", "note"].map(
                (field) => (
                  <TextField
                    key={field}
                    fullWidth
                    label={t.labels[field as keyof typeof t.labels]}
                    variant="outlined"
                    id={field}
                    name={field}
                    value={formik.values[field]}
                    onChange={formik.handleChange}
                    error={
                      formik.touched[field] && Boolean(formik.errors[field])
                    }
                  />
                )
              )}

              {/* Privacy and Marketing */}
              <FormControlLabel
                control={
                  <Checkbox
                    id="privacy"
                    name="privacy"
                    value={1}
                    onChange={formik.handleChange}
                  />
                }
                label={
                  <>
                    {t.labels.privacy}{" "}
                    <Link
                      title="Privacy policy"
                      type="external"
                      href={t.privacyLink}
                      underline="always"
                    >
                      privacy policy
                    </Link>
                  </>
                }
              />
              <FormControlLabel
                control={
                  <Checkbox
                    id="marketing"
                    name="marketing"
                    value={1}
                    onChange={formik.handleChange}
                  />
                }
                label={t.labels.marketing}
              />

              <Box justifyContent="flex-end" display="flex">
                <Button
                  type="submit"
                  variant="contained"
                  disabled={formik.isSubmitting}
                >
                  {t.labels.submit}
                </Button>
              </Box>
            </Stack>
          </form>
          {isSent && (
            <Box mt={4}>
              <Alert severity="success">
                <AlertTitle>{t.labels.successTitle}</AlertTitle>
                {t.labels.successMessage}
              </Alert>
            </Box>
          )}
        </Grid>
      </Grid>
    </Section>
  );
};

export default CampioneGratuitoForm;
